import React from 'react';
import {Container} from 'react-bootstrap';
import Header from "../components/Header";
import Footer from "../components/Footer";
import imgUrl from '../assests/aerial_photo.jpg';

function HomePage() {
    return (
        <>
            <Header/>
            <Container className="py-3 pb-5">
                <h1 className="text-dark py-3">Welcome to Centerburg Meadows Homeowners Association!</h1>
                    <p className="text-secondary">
                        Welcome to the official website of the Centerburg Meadows Homeowners Association (HOA)! We've
                        dedicated this website to serve as your primary source of information and updates related to our
                        beloved community, Centerburg Meadows.
                    </p>
                    <p className="text-secondary">
                        We, the Centerburg Meadows HOA, are committed to maintaining the beauty, safety, and value of
                        the
                        residences within our community. Our HOA is governed by a volunteer board of homeowners who
                        dedicate time and effort towards improving our neighborhood and ensuring it remains
                        an
                        incredible place to live.
                    </p>
                    <img src={imgUrl}
                         alt="Centerburg Meadows HOA"
                         className="float-end mx-3 mb-3 rounded"
                         style={{width: '200px', height: 'auto'}}
                    />
                    <p className="text-secondary">
                        On our website, you'll find a wealth of resources including the latest HOA meeting minutes,
                        community news, event calendars, and important documents such as community by-laws and
                        guidelines.
                        We also offer an FAQ section to address common questions you might have about living in
                        Centerburg
                        Meadows.
                    </p>
                    <p className="text-secondary">
                        The website is also an excellent place to voice your concerns or ideas for the community. Feel
                        free
                        to reach out to us via the 'Contact Us' page. We always look forward to hearing from our
                        residents!
                    </p>
                    <p className="text-secondary">
                        We encourage all homeowners and residents of Centerburg Meadows to explore the site and stay
                        updated
                        with all the happenings in our community. We are confident that with your support and
                        participation,
                        Centerburg Meadows will continue to flourish for years to come.
                    </p>
                    <p className="text-secondary">
                        Thank you for being a part of the Centerburg Meadows Homeowners Association! Let's work together
                        to
                        make our community the best place to call home.
                    </p>
            </Container>

            <Footer/>
        </>
    )
        ;
}

export default HomePage;