import React from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import Header from "../components/Header";
import Footer from "../components/Footer";
import dataHelper from "../utilities/dataHelper";

function ContactPage() {
    const {address, contact} = dataHelper.hoaDetails
    const {getBoardMembers} = dataHelper.membersData


    return (<>
            <Header/>
            <Container className="my-5 pb-5">
                <h2 className="text-center mb-4">Meet Our Board Members</h2>

                <Row className="justify-content-center">
                    {getBoardMembers().map((member) => (<Col key={member.id} xs={12} md={6} lg={4} className="mb-4">
                            <Card>
                                {/*<Image src={member.image} alt={`${member.name} - ${member.position}`} fluid/>*/}
                                <Card.Body>
                                    <Card.Title>{member.name}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{member.position}</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>))}
                </Row>

                <Row className="mt-4 justify-content-center">
                    <Col className="text-center">
                        <h3>Contact Us</h3>
                        <p>e-mail: <a href={`mailto:${contact().email}`}>{contact().email}</a></p>
                        <p>mailing address: {address().addressOne} {address().city} {address().state}, {address().postal}</p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <Button variant="outline-dark" href={contact().faceBook} target="_blank"
                                rel="noopener noreferrer">
                            Facebook
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </>);
}

export default ContactPage;