import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';

import HomePage from "./pages/HomePage"
import InformationPage from "./pages/InformationPage";
import EventsPage from "./pages/EventsPage";
import ContactPage from "./pages/ContactPage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/contact" element={<ContactPage/>}/>
                <Route path="/events" element={<EventsPage/>}/>
                <Route path="/info" element={<InformationPage/>}/>
                <Route path="/home" element={<HomePage/>}/>
                <Route path="/" element={<HomePage/>}/>
            </Routes>
        </Router>
    </React.StrictMode>
);

