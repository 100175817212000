import React from 'react'
import dataHelper from "../utilities/dataHelper";

import {Container} from 'react-bootstrap'
import Navigation from "./Navigation";
const Header = () => {

    return (
        <Container>
            <header className="bg-dark text-white text-center rounded-bottom-5
            ">
                <h1 className={'pt-3'}>{dataHelper.hoaDetails.name().shortName}</h1>
                <Navigation/>
            </header>
        </Container>
    );
};

export default Header;