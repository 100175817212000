// Navigation.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import {Navbar, Nav, Container} from 'react-bootstrap';

const Navigation = () => {
    return (
        <Navbar bg="dark" data-bs-theme="dark" className="bg-secondary rounded-bottom-5">
            <Container className={'justify-content-center'}>
                <Nav>
                    <Nav.Item>
                        <Link to="/home" className="nav-link">Home</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to="/info" className="nav-link">Info</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to="/events" className="nav-link">Events</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to="/contact" className="nav-link">Contact</Link>
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar>
    )
};

export default Navigation;
