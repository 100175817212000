// Footer.jsx
import React from 'react';
import dataHelper from "../utilities/dataHelper";
const Footer = () => {
    return (
        <footer className="bg-dark text-white text-center fixed-bottom mt-5">
            <p>&copy; {new Date().getFullYear()} {dataHelper.hoaDetails.name().formalName} All rights reserved.</p>
        </footer>
    );
};

export default Footer;
