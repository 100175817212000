const hoa_data = require('../data/hoa_data.json');
const events_data = require('../data/events_data.json');
const members_data = require('../data/members_data.json')
const documents_data = require('../data/documents_data.json')

const dataHelper = (function () {
    const privateMethods = {
        formatDates: (date) => {
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        },
        formatAndSort: (events, direction = 'asc') => {
            switch (direction) {
                case 'asc':
                    events.sort((eventA, eventB) => new Date(eventA.date) - new Date(eventB.date));
                    break;
                case 'desc':
                    events.sort((eventA, eventB) => new Date(eventB.date) - new Date(eventA.date));
                    break;
                default:
                    break;
            }

            return events.map(event => {
                return ({
                    ...event,
                    date: privateMethods.formatDates(event.date)
                })
            })
        },
        defaultEvent: (type) => {
            return (
                [{
                    "title": `No ${type} Events`,
                    "date": `${privateMethods.formatDates(new Date())}`,
                    "description": "Check back again with us later!"
                }]
            )
        },
        orderById: (arr) => {
            const copyData = [...arr];
            return copyData.sort((a, b) => a.id - b.id);
        },
        documentObject: (obj) => {
            return {
                title: obj.title,
                file_name: obj.file_name,
                date: obj.date,
                link: obj.link,
                md5: obj.md5

            }
        }
    }
    const hoaDetails = {
        name: () => {
            return {
                shortName: hoa_data.shortName || '',
                formalName: hoa_data.formalName || ''
            }

        },
        address: () => {
            return {
                addressOne: hoa_data.addressOne || '',
                addressTwo: hoa_data.addressTwo || '',
                city: hoa_data.city || '',
                state: hoa_data.state || '',
                postal: hoa_data.postal || '',
            }
        },
        contact: () => {
            return {
                email: hoa_data.email || '',
                faceBook: hoa_data.faceBookPage || ''
            }

        }
    }
    const eventData = {
        getUpcomingEvents: () => {
            const currentDate = new Date();
            const upcomingEvents = events_data.filter(event => new Date(event.date) >= currentDate);

            if (upcomingEvents.length === 0) {
                return (
                    privateMethods.defaultEvent('Upcoming')
                )
            }

            return privateMethods.formatAndSort(upcomingEvents)
        },
        getPreviousEvents: () => {
            const currentDate = new Date();
            const previousEvents = events_data.filter(event => new Date(event.date) <= currentDate);

            if (previousEvents.length === 0) {
                return (
                    privateMethods.defaultEvent('Previous')
                )
            }

            return privateMethods.formatAndSort(previousEvents);
        },
        getPaymentReminders: () => {
            return (
                [
                    {
                        title: '1st Payment Due:',
                        date: `February 28th, ${new Date().getFullYear()}`,
                        description: 'A friendly reminder to make your HOA payment before the deadline.',
                    },
                    {
                        title: '2nd Payment Due:',
                        date: `August 31st, ${new Date().getFullYear()}`,
                        description: 'A friendly reminder to make your HOA payment before the deadline.',
                    },
                ]
            )
        }
    }
    const membersData = {
        getBoardMembers: () => {
            return privateMethods.orderById(members_data)
        }

    }
    const documentsData = {
        importantDocuments: () => {
            const documents = privateMethods.formatAndSort(documents_data['important_documents'], 'desc')
            return documents.map(document => {
                return privateMethods.documentObject(document)
            })
        },
        meetingDocuments: () => {
            const documents = privateMethods.formatAndSort(documents_data['meeting_agendas_minutes'], 'desc')
            return documents.map(document => {
                return privateMethods.documentObject(document)
            })
        },
        financialDocuments: () => {
            const documents = privateMethods.formatAndSort(documents_data['treasurer_reports'], 'desc')
            return documents.map(document => {
                return privateMethods.documentObject(document)
            })
        }
    }

    return {
        hoaDetails: hoaDetails,
        eventData: eventData,
        membersData: membersData,
        documentsData: documentsData
    };
})();

export default dataHelper;
