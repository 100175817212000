import React from 'react';

import {Container, Card, ListGroup} from 'react-bootstrap'
import Header from "../components/Header";
import Footer from "../components/Footer";
import dataHelper from "../utilities/dataHelper";
import DocumentItem from "../components/DocumentItem";


function InformationPage() {
    const {address, name} = dataHelper.hoaDetails

    return (
        <>
            <Header/>
            <Container className={'mb-5'}>
                <Card className="mb-3 mt-3">
                    <Card.Body>
                        <Card.Title>Payment Due Dates</Card.Title>
                        <Card.Text className="text-muted">
                            Payments are due on the following dates:
                        </Card.Text>
                        <ListGroup>
                            <ListGroup.Item>
                                <div>
                                    <span>February 28th - $60.00</span>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div>
                                    <span>August 31st - $60.00</span>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                        <Card.Text className="text-muted mt-2">
                            $25.00 fee will be applied to late payments
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title>How to Pay</Card.Title>
                        <ListGroup>
                            <ListGroup.Item>
                                <Card.Subtitle className="mb-2 text-muted">Mail checks to: </Card.Subtitle>
                                <div className={'ms-3'}>
                                    <div>{name().formalName}</div>
                                    <div>{address().addressOne}</div>
                                    <div><p>{address().city} {address().state}, {address().postal}</p>
                                    </div>
                                </div>
                                <Card.Subtitle className={"mt-2 text-muted"}>Please include your street address on the
                                    check.</Card.Subtitle>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
                <Card className="mb-3 mt-3">
                    <Card.Body>
                        <Card.Title>Public Documents</Card.Title>
                        <ListGroup>
                            <ListGroup.Item>
                                <Card.Subtitle className={"mt-2 text-muted"}>Essential Records</Card.Subtitle>
                                <ListGroup>
                                    <ListGroup.Item>
                                        <div className="d-flex flex-wrap">
                                            {
                                                dataHelper.documentsData.importantDocuments().map((item, index) => (
                                                    <DocumentItem
                                                        index={index}
                                                        item={item}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Card.Subtitle className={"mt-2 text-muted"}>Annual Meeting Minutes & Agendas</Card.Subtitle>
                                <ListGroup>
                                    <ListGroup.Item>
                                        <div className="d-flex flex-wrap">
                                            {
                                                dataHelper.documentsData.meetingDocuments().map((item, index) => (
                                                    <DocumentItem
                                                        index={index}
                                                        item={item}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Card.Subtitle className={"mt-2 text-muted"}>Financial Reports</Card.Subtitle>
                                <ListGroup>
                                    <ListGroup.Item>
                                        <div className="d-flex flex-wrap">
                                            {
                                                dataHelper.documentsData.financialDocuments().map((item, index) => (
                                                    <DocumentItem
                                                        index={index}
                                                        item={item}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Container>
            <Footer/>
        </>
    );
}

export default InformationPage;