import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap'
import Header from "../components/Header";
import Footer from "../components/Footer";
import dataHelper from "../utilities/dataHelper";

function EventsPage() {

    return (
        <>
            <Header/>
            <Container className="my-5">
                <h2 className="text-center mb-4">Payment Reminders</h2>
                <Row className="justify-content-center">
                    {dataHelper.eventData.getPaymentReminders().map((event, index) => (
                        <Col key={index} xs={12} md={6} lg={4} className="mb-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>{event.title}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{event.date}</Card.Subtitle>
                                    <Card.Text>{event.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <h2 className="text-center mb-4">Upcoming Events</h2>
                <Row className="justify-content-center">
                    {dataHelper.eventData.getUpcomingEvents().map((event, index) => (
                        <Col key={index} xs={12} md={6} lg={4} className="mb-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>{event.title}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{event.date}</Card.Subtitle>
                                    <Card.Text>{event.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <h2 className="text-center mb-4">Previous Events</h2>
                <Row className="justify-content-center">
                    {dataHelper.eventData.getPreviousEvents().map((event, index) => (
                        <Col key={index} xs={12} md={6} lg={4} className="mb-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>{event.title}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{event.date}</Card.Subtitle>
                                    <Card.Text>{event.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Footer/>
        </>
    );
}

export default EventsPage;