// DocumentItem.jsx
import React from 'react';
import icons from "./Icons";
const DocumentItem = (props) => {
    return (
        <div key={props.index} className="m-2">
            <div className="d-flex align-items-center">
                <div className="me-2 text-truncate" style={{maxWidth: '225px'}}>
                    <a href={props.item.link} title={props.item.title} download
                       rel="noopener noreferrer">
                        {props.item.title}
                    </a>
                </div>
                {icons.svg.cloudDownload()}
            </div>
            <div style={{fontSize: '0.8rem'}}
                 className="text-muted text-sm d-flex ms-3">
                <span><strong>date:</strong> {props.item.date}</span>
            </div>
            <div style={{fontSize: '0.8rem'}}
                 className="text-truncate d-inline-block w-75 text-muted text-sm ms-3">
                <span><strong>md5:</strong> {props.item.md5}</span>
            </div>
        </div>
    );
};

export default DocumentItem;
